<template>
  <b-row
    data-anima="top"
    class="container-box padding-content col-sm-12 col-md-12 col-12"
  >
    <modal-upload />
    <div class="uploading-container">
      <my-upload
        v-model="show"
        field="value"
        langType="pt-br"
        :width="100"
        :height="100"
        :url="urlPost"
        :params="params"
        :headers="headers"
        img-format="png"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
      />
      <img :src="imgDataUrl" />
    </div>

    <b-col cols="12" md="3" sm="12" class="padding-clear" v-if="!isMobile">
      <h1 :class="`default-font-${defaultFont}`" class="side-title">{{ $t("minha_conta.titleV2") }}</h1>
      <b-tabs
        data-anima="top"
        pills
        card
        vertical
        align="left"
        nav-class="navigation-class"
        nav-wrapper-class="w-100"
        nav-item-class="active-item active-tab"
        active-nav-item-class="active-item active-tab"
        active-tab-class="font-weight-light text-success"
      >
        <b-tab
          :title="$t('minha_conta.my_profile')"
          active
          title-link-class="item-content"
          @click="scrollToContainer('container-user')"
        />
        <b-tab
          :title="$t('general.security')"
          title-link-class="item-content"
          @click="scrollToContainer('container-security')"
        />
        <b-tab
          :title="$t('minha_conta.language_and_region')"
          title-link-class="item-content"
          @click="scrollToContainer('container-region')"
        />
        <b-tab
          :title="$t('general.completed_courses')"
          title-link-class="item-content"
          @click="scrollToContainer('container-certificate')"
        />
      </b-tabs>
    </b-col>
    <b-col cols="12" md="9" sm="12" class="padding-clear container-margin">
      <div class="container-content">
        <div class="user-data">
          <div @click="togglePicture">
            <b-avatar
              class="card-avatar"
              :size="isMobile ? '5rem' : '6rem'"
              variant="info"
              v-b-tooltip.hover
              :title="$t('minha_conta.txt6')"
              :src="picProfile"
              style="cursor: pointer"
            />
          </div>
          <div class="user-info">
            <b class="titleAll">
              {{ firstName }}
            </b>
            <span class="member-since">
              <font-awesome-icon
                style="margin-right: 10px"
                icon="fa-regular fa-calendar"
              />
              {{ $t("minha_conta.member") }}
              {{ membro | moment("DD/MM/YYYY HH:mm:ss") }}
            </span>
          </div>
        </div>
      </div>

      <div id="container-user" class="container-content" v-if="!isSmall">
        <header>
          <h1 class="mb-5">{{ $t("minha_conta.user_data") }}</h1>

          <span class="content-bottom">
            <span
              class="lefts"
              data-anima="top"
              v-if="!editing"
              @click="toggleEdit"
            >
              {{ $t("general.edit") }}
            </span>
            <span
              class="lefts"
              data-anima="top"
              v-if="editing"
              @click="updateDataName"
            >
              {{ $t("general.save") }}
            </span>

            <font-awesome-icon
              v-if="editing && !isLoading"
              class="loading-icon"
              style="margin-right: 10px"
              icon="fa-solid fa-check"
            />
            <font-awesome-icon
              v-if="editing && isLoading"
              class="spinner loading-icon"
              style="margin-right: 10px"
              icon="fa-solid fa-spinner fa-spin"
            />
            <img
              v-if="!editing"
              class="icon rights"
              src="@/assets/icons/pen-edit.svg"
            />
          </span>
        </header>
        <div class="flex-content">
          <b-col cols="12" md="5" sm="12" class="padding-clear first-col">
            <h2 class="user-title">
              {{ $t("minha_conta.first_name") }}
            </h2>
            <span class="span-second name-space" v-if="!editing">
              {{ firstName }}
            </span>
            <input
              v-else
              v-model="firstName"
              class="input-design inline-edit"
              id="input-old-password"
              type="text"
              data-anima="top"
              size="sm"
            />

            <h2 class="user-title">
              {{ $t("minha_conta.mail_address") }}
            </h2>
            <span class="span-second">
              {{ email }}
            </span>
          </b-col>
          <b-col cols="12" md="5" sm="12" class="padding-clear">
            <div>
              <h2 class="user-title">
                {{ $t("minha_conta.telephone") }}
              </h2>
              <span class="span-second" v-if="!editing">
                {{ phoneNumber }}
              </span>
              <input
                v-else
                v-model="phoneNumber"
                class="input-design inline-edit"
                id="input-old-password"
                type="text"
                size="sm"
                data-anima="top"
              />
            </div>
          </b-col>
        </div>
      </div>

      <div id="container-user" class="container-content" v-if="isSmall">
        <header>
          <h1 class="mb-2">Meu perfil</h1>
        </header>
        <div class="user-table-mobile">
          <div class="line">
            <h2 class="column">
              {{ $t("minha_conta.name") }}
            </h2>
            <span class="column">
              {{ firstName }}
            </span>
          </div>

          <div class="line">
            <h2 class="column">
              {{ $t("general.mail") }}
            </h2>
            <span class="column">
              {{ email }}
            </span>
          </div>
          <div class="line">
            <h2 class="column">
              {{ $t("minha_conta.telephone") }}
            </h2>
            <span class="column">
              {{ phoneNumber }}
            </span>
          </div>
        </div>
      </div>
      <div
        data-anima="top"
        id="container-security"
        class="container-content"
        v-if="!changePassword"
      >
        <header>
          <h1>
            {{ $t("general.security") }}
          </h1>
          <span
            class="content-bottom"
            @click="changePassword = !changePassword"
          >
            <span class="lefts"> {{ $t("minha_conta.change_password") }}</span>
            <img class="icon rights" src="@/assets/icons/pen-edit.svg" />
          </span>
        </header>
      </div>
      <div
        data-anima="top"
        id="container-security"
        class="container-content"
        v-if="changePassword"
      >
        <header>
          <h1 class="security">
            {{ $t("general.security") }}
          </h1>
        </header>
        <span
          class="close-change-password"
          @click="changePassword = !changePassword"
        >
          <font-awesome-icon
            style="margin-right: 10px"
            icon="fa-solid fa-xmark"
          />
        </span>
        <div class="change-password">
          <div>
            <label class="input-password" for="input-old-password">
              {{ $t("minha_conta.label_password") }}
            </label>
            <b-form-input
              class="input-design"
              id="input-old-password"
              type="password"
              size="sm"
              v-model="lastPassword"
            />
          </div>
          <div>
            <label class="input-password" for="input-new-password">
              {{ $t("minha_conta.label_new_password") }}
            </label>
            <b-form-input
              class="input-design"
              id="input-new-password"
              type="password"
              size="sm"
              v-model="newPassword"
            />
          </div>
          <div class="button-change-password" @click="updatePassword">
            {{ $t("minha_conta.update_password") }}
          </div>
        </div>
      </div>
      <div id="container-region" class="container-content">
        <header>
          <h1>{{ $t("minha_conta.language_and_region") }}</h1>
          <span
            class="content-bottom"
            v-if="!changeCountry"
            @click="toggleLocale"
          >
            <span class="lefts"> {{ $t("locales.trocar") }} </span>
            <img
              class="icon rights"
              style="width: 16px"
              :src="require(`@/assets/locales/flag-${locale}.png`)"
            />
          </span>
        </header>
        <LocalesV2 data-anima="top" v-if="changeCountry" />
      </div>

      <div id="container-certificate" class="container-content">
        <header>
          <h1>{{ $t("general.completed_courses") }} 🎉</h1>

          <span class="content-bottom" @click="$router.push('/certificates')">
            <span class="lefts"> {{ $t("certificates_component.meus") }} </span>
            <img class="icon rights arrow" src="@/assets/icons/arrow-top.svg" />
          </span>
        </header>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { EventBus } from "@/main.js";

import MemberMetaService from "@/services/resources/MemberMetaService";
import MemberService from "@/services/resources/MemberService";
import MetaService from "@/services/resources/MetaService";
import notify from "@/services/libs/notificacao";

import ModalUpload from "@/components/V2/modals/ModalUpload.vue";
import myUpload from "vue-image-crop-upload";
import Cookies from "js-cookie";

import LocalesV2 from "@/components/V2/configs/Locales.vue";

const serviceMember = MemberService.build();
const serviceMeta = MetaService.build();
const serviceMemberMeta = MemberMetaService.build();

export default {
  name: "MinhaContaV2",
  components: {
    LocalesV2,
    ModalUpload,
    "my-upload": myUpload,
  },
  data() {
    return {
      email: "",
      membro: "",
      client: {
        width: 0,
      },
      locale: localStorage.getItem("location") ?? "BR",
      changeCountry: false,
      isLoading: false,
      editing: false,
      changePassword: false,
      picProfile: null,
      NewPicProfileId: "",
      NewPicProfileUrl: "",
      logoPainel: null,
      NewLogoId: "",
      NewLogoUrl: "",
      color: "#00E4A0",
      swatches: [["#FF0C37"], ["#8b5aff"], ["#00E4A0"], ["#ffa51a"]],
      ratingHome: true,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      show: false,
      params: {},
      headers: {
        Authorization: Cookies.get("auth_greennCourse"),
      },
      imgDataUrl: "",
      urlPost: process.env.VUE_APP_API_HOST + "/member/meta/picture",
      newPassword: null,
      lastPassword: null,
    };
  },
  computed: {
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    },
    currentSite() { 
      return this.$store.getters.currentSite || {
        currentMember: {},
        currentSite: {},
      };
    },
    isMobile() {
      return this.client.width <= 580;
    },
    isSmall() {
      return this.client.width <= 762;
    },
  },
  created() {
    this.fetchCountry();
    EventBus.$on("atualizaPais", (data) => {
      this.locale = data.locale;
    });
    EventBus.$on("SelectLocale", (data) => {
      this.locale = data.locale;
      this.changeCountry = false;
    });

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    toggleEdit() {
      this.editing = !this.editing;
    },
    toggleLocale() {
      this.changeCountry = !this.changeCountry;
    },
    togglePicture() {
      this.show = !this.show;
    },
    scrollToContainer(containerId) {
      const container = document.getElementById(containerId);

      container.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
    fetchCountry() {
      serviceMemberMeta
        .search("keys[]=pais")
        .then((resp) => {
          if (resp.pais !== undefined) {
            if (resp.pais === null) {
              this.fetchCountrySite();
            } else {
              this.locale = resp.pais;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updatePassword() {
      var data = {
        id: "change-password",
        password: this.lastPassword,
        new_password: this.newPassword,
      };

      serviceMember
        .postID(data)
        .then(() => {
          this.newPassword = null;
          this.lastPassword = null;
          notify("sucesso", this.$t("minha_conta.errors.update"));
        })
        .catch((err) => {
          console.error(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") notify("erro", msg);
        });
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.picProfile = imgDataUrl;
    },
    cropUploadSuccess() {
      this.show = false;
      notify("sucesso", this.$t("minha_conta.errors.perfil"));
      this.$root.$emit("changedprofilepic");
    },
    cropUploadFail() {
      notify("erro", this.$t("minha_conta.errors.perfil_error"));
      this.show = false;
    },
    updateDataName() {
      var data = {
        id: "meta",
        name: this.firstName,
        cellphone: this.phoneNumber,
      };
      this.isLoading = true;
      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then(() => {
          notify("sucesso", this.$t("minha_conta.errors.atualizado"));
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.toggleEdit();
          this.$root.$emit("loadOff");
          this.isLoading = false;
        });

      setTimeout(() => {
        this.getUserLogged();
      }, 2000);
    },
    getUserLogged() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.firstName = resp.first_name;
          this.phoneNumber = resp.cellphone;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    changedRating() {
      var status = this.ratingHome === true ? "on" : "off";
      let data = {
        id: "rating_home",
        value: status,
      };
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => this.getRating())
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getRating() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=rating_home")
        .then((resp) => {
          if (resp.rating_home === null || resp.rating_home === "on") {
            this.ratingHome = true;
          } else {
            this.ratingHome = false;
          }
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    changedColor() {
      var data = {
        id: "main_color",
        value: this.color,
      };

      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => {
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute("data-color", this.color);
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            );
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            );
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            );
          }
          this.getColor();
          this.$root.$emit("changedmaincolor");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getColor() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=main_color")
        .then((resp) => {
          this.color = resp.main_color;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.NewLogoUrl,
      };

      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then(() => {
          this.getLogo();
          this.$root.$emit("changedlogo");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getLogo() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=logo")
        .then((resp) => {
          this.logoPainel = resp.logo;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    updateProfilePic() {
      var data = {
        id: "meta/picture",
        value: this.NewPicProfileUrl,
      };

      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then(() => {
          this.getPicProfile();
          this.$root.$emit("changedprofilepic");
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    getPicProfile() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.picProfile = resp.picture;
        })
        .catch((err) => console.error(err))
        .finally(() => this.$root.$emit("loadOff"));
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getCurrentSiteAndMember() { 
      this.email = this.currentSite.currentMember.email;
      this.membro = this.currentSite.currentMember.created_at;
    },
    showModalUploadPic() {
      this.$root.$emit("picprofile");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
  },
  mounted() {
    this.getCurrentSiteAndMember();
    this.getUserLogged();
    this.getPicProfile();
    this.getLogo();
    this.getColor();
    this.getRating();
    this.$root.$on("newpicprofile", (data) => {
      this.NewPicProfileId = parseInt(data.id);
      this.NewPicProfileUrl = data.url;
      this.updateProfilePic();
    });
    this.$root.$on("newlogopainel", (data) => {
      this.NewLogoId = parseInt(data.id);
      this.NewLogoUrl = data.url;
      this.updateLogoPainel();
    });
  },
};
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.first-col {
  margin-bottom: 44px;
}

.uploading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploading-container .vue-image-crop-upload {
  position: relative;
  margin: 0 auto;
}

.uploading-container .vue-image-crop-upload .vicp-wrap {
  background-color: var(--background2-v2);
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::before {
  background-color: var(--fontcolor-v2) !important;
}

.vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::after {
  background-color: var(--fontcolor-v2) !important;
}

.vue-image-crop-upload .vicp-wrap .vicp-close {
  display: none;
}

.uploading-container .vue-image-crop-upload .vicp-wrap .vicp-operate a {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  border-radius: 50px !important;
  color: var(--fontcolor-v2) !important;
  border-radius: 50px !important;
  background: var(--background-v2) !important;
}

.member-since {
  color: var(--fontcolor2-v2) !important;
}
.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loading-icon {
  margin-right: 10px;
  top: 4px !important;
  position: relative;
  left: 10px;
}

.inline-edit {
  border: 0 !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
  margin-bottom: 10px !important;
}

.close-change-password {
  width: 30px;
  height: 30px;
  color: var(--fontcolor-v2);
  background: var(--background2-v2);
  display: block;
  border-radius: 50%;
  position: relative;
  padding: 5px 0px 0px 9.5px;
  float: right;
  top: -70px;
  left: 16px;
  cursor: pointer;
}
.button-change-password {
  width: 214px;
  height: 40px;
  color: var(--fontcolor3-v2);
  background: var(--maincolor);
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 10px 10px 34px 10px;
  margin-top: 25px;
  cursor: pointer;
}

.input-design {
  width: 277px;
  height: 40px;
  background: var(--background2-v2);
  border-radius: 50px;
  margin-right: 30px;
}

.input-password {
  font-size: 14px;
}

.security {
  display: block !important;
  margin-bottom: 30px;
}
.change-password {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.user-table-mobile {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.user-table-mobile .line {
  display: table-row;
  background: transparent;
}

.user-table-mobile .column {
  display: table-cell;
  padding: 5px;
}

header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.padding-content {
  padding-left: 18px;
  padding-right: 18px;
}
@media screen and (min-width: 768px) {
  .padding-content {
    padding-left: 56px;
    padding-right: 56px;
  }
}

.container-box {
  margin-top: 70px;
  display: flex;
}

.flex-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.navigation-class {
  background-color: transparent;
}

.name-space {
  margin-bottom: 40px;
}

.container-margin {
  margin-top: 50px;
}

.span-second {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.user-title {
  margin-bottom: 15px;
}

.active-tab {
  color: var(--maincolor) !important;
}

.item-content {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: var(--text-color) !important;
}

.nav-item a {
  color: var(--text-color) !important;
}

.titleAll {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon {
  margin-left: 10px;
  position: relative;
  top: 3px;
  width: 13px;
  height: 16px;
}

.content-bottom {
  width: auto;
  height: 50px;
  font-size: 15px;
  border-radius: 50px;
  padding: 13px 20px 0px 20px;
  border-color: transparent;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
  background: var(--background2-v2);
}

.content-bottom .lefts {
  color: var(--fontcolor-v2);
  margin-left: 5px;
}

.content-bottom .rights {
  color: var(--fontcolor-v2);
  margin-left: 20px;
}

.content-bottom .arrow {
  position: relative;
  width: 10px;
  height: 10px;
  top: 8px !important;
}

.active-item {
  color: var(--text-color) !important;
  background: var(--maincolor) !important;
  border-radius: 50px !important;
  padding: 15px;
  font-weight: 600px;
  width: auto !important;
  transition: 0.25s ease-out;
  filter: opacity(50%);
}

.side-title {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  margin-left: 21px;
  color: var(--fontcolor-v2);
}

.container-content {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 35px;
  color: var(--fontcolor-v2);
  border: 1px solid var(--bordercolor-v2);
}

.container-content .user-data {
  border-radius: 25px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;
}

.container-content .user-info {
  text-align: left;
  margin: 14px 10px 10px 40px;
}

.container-content h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.container-content h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: var(--fontcolor2-v2);
}

.container-content span {
  color: var(--fontcolor-v2);
}
.padding-clear {
  padding: 0px !important;
}
.margin-align {
  margin-top: 106px;
}
@media screen and (max-width: 580px) {
  .change-password {
    width: 100%;
  }

  .change-password div {
    width: 100%;
    margin-bottom: 20px;
  }

  .input-design {
    width: 100%;
  }

  header {
    flex-wrap: wrap;
  }
  .container-margin {
    margin: 0;
  }
  .content-bottom {
    width: 100%;
  }
  .content-bottom .rights {
    margin-right: -20px;
    top: 5px;
  }
  .content-bottom .lefts {
    position: relative;
    top: 2px;
  }
  .container-content h1 {
    margin-bottom: 25px;
  }

  .container-content h2 {
    color: var(--fontcolor2-v2);
  }
  .container-content span {
    color: var(--fontcolor-v2);
  }

  .titleAll {
    margin-bottom: 10px;
  }
  .container-content .user-info {
    margin: 5px 10px 10px 20px;
  }

  .container-content {
    border: 0;
    border-radius: 0;
    border-top: 1px solid var(--bordercolor-v2);
    margin-bottom: 0;
    padding: 30px 18px 30px 18px;
  }
  .row {
    margin: 0;
  }
}
</style>
